export const cover = require("./cover.jpg");
export const hero = require("./hero.png");
export const ogImage = require("./ogImage.png");
export const ichrp = require("./ichrp.png");
export const casambre = require("./casambre.jpg");
export const freekarina = require("./freekarina.png");
export const ichrpHorizontal = require("./ichrpHorizontal.png");
export const ichrpLogo = require("./ichrpLogo.png");
export const ichrpCanadaLogo = require("./ichrpCanadaLogo.svg");
export const cpshr = require("./cpshr.svg");
export const ucc = require("./ucc.svg");
export const fappp = require("./fappp.jpg");
export const write = require("./write.svg");
export const help = require("./help.jpg");
export const fides = require("./fides.jpg");
export const perry = require("./perry.png");
export const ReadingDoodle = require("./ReadingDoodle.svg");
